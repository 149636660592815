import { useState, useEffect } from 'react';
import { ResponsiveBar } from '@nivo/bar';
import Page from 'components/Page';
import { styled } from '@mui/material/styles';
import { TextField, Grid, IconButton, Card, CardContent, Box, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { GetNewUserappoinmentDetails } from 'redux/slices/report';
import { RootState } from 'redux/store';
import ClearIcon from '@mui/icons-material/Clear';
import { Button } from '@mui/material';
import useLocales from 'hooks/useLocales';
import { CardsNames } from 'pages/constants';
import { useTranslation } from 'react-i18next';
import Scrollbar from 'components/Scrollbar';

const CustomCard = styled(Card)(({ theme }) => ({
  backgroundColor: '#fff',
  color: '#212B36',
  transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  borderRadius: '16px',
  boxShadow: '0 0 2px 0 rgba(145, 158, 171, 0.24), 0 16px 32px -4px rgba(145, 158, 171, 0.24)',
  position: 'relative',
  zIndex: 0,
  padding: '1.5rem 1rem',
  '&:hover': {
    boxShadow: '0 0 4px 0 rgba(145, 158, 171, 0.5), 0 20px 40px -4px rgba(145, 158, 171, 0.3)'
  },
  marginBottom: '20px'
}));

const ToolTip = styled('div')(() => ({
  backgroundColor: '#fff',
  padding: '5px 10px',
  border: '1px solid #DFE3E8',
  fontSize: '12px',
  borderRadius: '5px',
  direction: 'rtl',
  color: '#333',
  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
  zIndex: 9999,
  // position: 'absolute', 
  // pointerEvents: 'none', 
  // width: '250px'
}));

const SimpleChart = () => {
  const dispatch = useDispatch();
  const { currentLang } = useLocales();
  const { t } = useTranslation();
  const { GetNewUserappoinment } = useSelector((state: RootState) => state.report);
  const [fromDate, setFromDate] = useState(new Date().toISOString().split('T')[0]);
  const [toDate, setToDate] = useState(new Date().toISOString().split('T')[0]);
  const [data, setData] = useState<
    {
      category: string;
      registered: number;
      triedToBook: number;
      successfullyBooked: number;
      requests: number;
    }[]
  >([]);
  const [totalCounts, setTotalCounts] = useState({
    registered: 0,
    triedToBook: 0,
    successfullyBooked: 0,
    requests: 0
  });

  useEffect(() => {
    if (fromDate !== '' && toDate !== '') {
      dispatch(GetNewUserappoinmentDetails(fromDate, toDate));
    }
  }, [dispatch, fromDate, toDate]);

  useEffect(() => {
    if (GetNewUserappoinment && GetNewUserappoinment.length > 0) {
      const mappedData = GetNewUserappoinment.map((item: any) => ({
        category: new Date(item.date).toLocaleDateString(),
        registered: item.registeredUserCount || 0,
        triedToBook: item.triedAppointmentsCount || 0,
        successfullyBooked: item.bookedAppointmentsCount || 0,
        requests: item.requestCount || 0
      }));
      const totalRegistered = mappedData.reduce((sum, item) => sum + item.registered, 0);
      const totalTriedToBook = mappedData.reduce((sum, item) => sum + item.triedToBook, 0);
      const totalSuccessfullyBooked = mappedData.reduce(
        (sum, item) => sum + item.successfullyBooked,
        0
      );
      const totalRequests = mappedData.reduce((sum, item) => sum + item.requests, 0);

      setData(mappedData);
      setTotalCounts({
        registered: totalRegistered,
        triedToBook: totalTriedToBook,
        successfullyBooked: totalSuccessfullyBooked,
        requests: totalRequests
      });
    }
  }, [GetNewUserappoinment]);

  const handleClearFilter = () => {
    setFromDate(new Date().toISOString().split('T')[0]);
    setToDate(new Date().toISOString().split('T')[0]);
    dispatch(GetNewUserappoinmentDetails(new Date().toISOString().split('T')[0], ''));
  };

  const colors = ['#d7ac47', '#fd8d3c', '#74c476', '#9e9ac8'];
  const tooltipLabels = {
    registered: currentLang?.value === 'en' ? 'New User Registered' : 'المستخدمين الجدد المسجلين',
    triedToBook:
      currentLang?.value === 'en'
        ? 'New Users Tried Booking Appointment'
        : 'عدد العملاء الجدد الذين حجزوا إستشارة',
    successfullyBooked:
      currentLang?.value === 'en' ? 'Successful Booked Appointment' : 'حجز موعد استشارة بنجاح',
    requests:
      currentLang?.value === 'en'
        ? 'How Many Asked for Request'
        : 'عدد العملاء الجدد الذين قدموا طلبًا'
  };

  return (
    <Page>
      <CustomCard>
        <CardContent>
          <Grid container spacing={2} style={{ marginBottom: '20px' }}>
            <Grid item xs={6} md={4}>
              <TextField
                label={t('from')}
                type="date"
                value={fromDate}
                onChange={(e) => setFromDate(e.target.value)}
                fullWidth
                InputLabelProps={{ shrink: true }}
                // inputProps={{
                //   min: getDate(1),
                //   max: getDate(0)
                // }}
              />
            </Grid>
            <Grid item xs={6} md={4}>
              <TextField
                label={t('to')}
                type="date"
                value={toDate}
                onChange={(e) => setToDate(e.target.value)}
                fullWidth
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={12} md={4} style={{ display: 'flex', alignItems: 'center' }}>
              <Button
                onClick={handleClearFilter}
                variant="outlined"
                endIcon={
                  <IconButton color="primary">
                    <ClearIcon />
                  </IconButton>
                }
              >
                {currentLang?.value === 'en' ? 'Clear Filter' : 'إزالة الفلتره'}
              </Button>
            </Grid>
          </Grid>
          <Scrollbar
            sx={{
              width: '100%',
              overflowY: 'hidden',
              overflowX: 'auto'
            }}
            // @ts-ignore
            scrollStyles={{
              bar: {
                backgroundColor: '#d49e24'
              },
              track: {
                backgroundColor: '#F4F6F8'
              }
            }}
          >
            <Box sx={{ height: 410, minWidth: '500px' }}>
              {' '}
              <ResponsiveBar
                data={data}
                keys={['registered', 'triedToBook', 'successfullyBooked', 'requests']}
                indexBy="category"
                margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
                padding={0.2}
                valueScale={{ type: 'linear' }}
                indexScale={{ type: 'band', round: true }}
                colors={colors}
                borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                axisTop={null}
                axisRight={null}
                axisBottom={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 45,
                  legendPosition: 'middle',
                  legendOffset: 32
                }}
                axisLeft={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: 'Value',
                  legendPosition: 'middle',
                  legendOffset: -40
                }}
                labelSkipWidth={30}
                labelSkipHeight={30}
                labelTextColor="white"
                animate={true}
                motionConfig="gentle"
                tooltip={({ id, value, color }) => {
                  const tooltipKey = id as keyof typeof tooltipLabels;
                  return (
                    <ToolTip>
                      <strong style={{ color }}>{tooltipLabels[tooltipKey]}</strong>: {value}
                    </ToolTip>
                  );
                }}
              />
            </Box>
          </Scrollbar>

          <Grid container spacing={2} style={{ marginTop: '20px' }}>
            {CardsNames?.map((card, index) => (
              <Grid item xs={12} sm={6} md={3} key={card.id}>
                <Box
                  style={{
                    backgroundColor: colors[index],
                    color: '#fff',
                    padding: '10px',
                    textAlign: 'center',
                    borderRadius: '8px',
                    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)'
                  }}
                >
                  <Typography sx={{ fontSize: '11px' }}>
                    {currentLang?.value === 'en' ? card.enName : card.arName}
                  </Typography>
                  <Typography variant="body2">
                    {
                      totalCounts[
                        index === 0
                          ? 'registered'
                          : index === 1
                          ? 'triedToBook'
                          : index === 2
                          ? 'successfullyBooked'
                          : 'requests'
                      ]
                    }
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </CardContent>
      </CustomCard>
    </Page>
  );
};

export default SimpleChart;
