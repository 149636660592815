import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
import RoleBasedGuard from '../guards/RoleBasedGuard';
import ProfileGuard from '../guards/ProfileGuard';
import StatusGuard from '../guards/StatusGuard';
// hooks
import useAuth from './../hooks/useAuth';
// components
import LoadingScreen from '../components/LoadingScreen';
import ConfigurationForm from 'pages/dashboard/configurationManagement/ConfigurationForm';
import AdminGeneralDahboard from 'pages/dashboard/adminGeneralDashboard/AdminGeneralDahboard';
import LawyerGeneralDahboard from 'pages/dashboard/lawyerGeneralDashboard/LawyerGeneralDahboard';
import MusherList from 'pages/dashboard/musherManagement/MusherList';
import QuestionsDetail from 'pages/dashboard/QuestionsDetail';
import B2bList from 'pages/dashboard/B2bManagement/B2bList';
import B2bDetails from 'pages/dashboard/B2bManagement/B2bDetails';
import Consultation from '../pages/Consultation';
import { PATH_DASHBOARD } from './paths';

// ----------------------------------------------------------------------

enum AppRole {
  Client = 'client',
  Admin = 'admin',
  Lawyer = 'lawyer',
  Employee = 'employee'
}

type IAppRole = 'Client' | 'Admin' | 'Lawyer' | 'Employee';

const Loadable = (Component: React.ElementType) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const isDashboard = pathname.includes('/dashboard');

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: 'fixed'
            })
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  const { user } = useAuth();
  const userRole = AppRole[user?.role as IAppRole];

  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          )
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          )
        },
        { path: 'login-unprotected', element: <Login /> },
        { path: 'register-unprotected', element: <Register /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'consultation-form', element: <Consulation /> },
        { path: 'verify', element: <VerifyCode /> },

        { path: 'forget-password', element: <ForgetPassword /> },

        {
          path: 'profile/complete',
          element: (
            <AuthGuard>
              <ProfileGuard>
                <ProfileComplete />
              </ProfileGuard>
            </AuthGuard>
          )
        },

        {
          path: 'account/pending',
          element: (
            <AuthGuard>
              <ProfileGuard>
                <StatusGuard>
                  <AccountPending />
                </StatusGuard>
              </ProfileGuard>
            </AuthGuard>
          )
        },
        {
          path: 'account/blocked',
          element: (
            <AuthGuard>
              <ProfileGuard>
                <StatusGuard>
                  <AccountBlocked />
                </StatusGuard>
              </ProfileGuard>
            </AuthGuard>
          )
        }
      ]
    },

    // pages
    {
      path: '/',
      element: (
        <AuthGuard>
          <ProfileGuard>
            <StatusGuard>
              <DashboardLayout />
            </StatusGuard>
          </ProfileGuard>
        </AuthGuard>
      ),
      children: [{ element: <Navigate to={`/dashboard/general-dashboard`} replace /> }]
    },

    // Dashboard Routes
    {
      path: '/dashboard',
      element: (
        <AuthGuard>
          <ProfileGuard>
            <StatusGuard>
              <DashboardLayout />
            </StatusGuard>
          </ProfileGuard>
        </AuthGuard>
      ),
      children: [
        {
          element:
            userRole?.toLowerCase() === 'admin' ||
            userRole?.toLowerCase() === 'employee' ||
            userRole?.toLowerCase() === 'lawyer' ? (
              <Navigate to={`/dashboard/general-dashboard`} replace />
            ) : (
              <Navigate to={`/dashboard/request/list/${userRole}/?`} replace />
            )
        },

        // { element: <Navigate to="/dashboard/app" replace /> },
        {
          path: 'general-dashboard',
          element:
            userRole?.toLowerCase() === 'admin' || userRole?.toLowerCase() === 'employee' ? (
              <RoleBasedGuard
                accessibleRoles={['Admin', user?.permission?.includes('main') && 'Employee']}
              >
                <AdminGeneralDahboard />
              </RoleBasedGuard>
            ) : userRole?.toLowerCase() === 'lawyer' ? (
              <RoleBasedGuard accessibleRoles={['Lawyer']}>
                <LawyerGeneralDahboard />
              </RoleBasedGuard>
            ) : (
              <RoleBasedGuard accessibleRoles={['Admin', 'Lawyer']}>Client</RoleBasedGuard>
            )
        },
        {
          path: 'request',
          children: [
            // { path: ':requestId/detail/:pages', element: <RequestDetail /> },
            {
              path: 'masterRequest/:status/:masterRequestId/detail/:pages',
              element: (
                <RoleBasedGuard
                  accessibleRoles={['Admin', user?.permission?.includes('requests') && 'Employee']}
                >
                  <MasterRequestDetail />
                </RoleBasedGuard>
              )
            },
            // { path: 'consulting', element: <RequestListClient /> },
            // { path: 'contract', element: <RequestListClient /> },

            {
              path: 'list/admin',
              element: (
                <RoleBasedGuard
                  accessibleRoles={['Admin', user?.permission?.includes('requests') && 'Employee']}
                >
                  <RequestListAdmin />
                </RoleBasedGuard>
              )
            },

            {
              path: 'list/client',
              element: (
                <RoleBasedGuard accessibleRoles={['client', 'Client']}>
                  <RequestListClient />
                </RoleBasedGuard>
              )
            },

            {
              path: 'list/lawyer',
              element: (
                <RoleBasedGuard accessibleRoles={['Lawyer']}>
                  <RequestListLawyer />
                </RoleBasedGuard>
              )
            }

            // {
            //   path: 'new',
            //   element: (
            //     <RoleBasedGuard accessibleRoles={['Admin', 'Client']}>
            //       <RequestCreate />
            //     </RoleBasedGuard>
            //   )
            // },

            // { path: ':requestId/edit', element: <RequestCreate /> }
          ]
        },
        {
          path: 'client',
          children: [
            { element: <Navigate to="/dashboard/client/list/?" replace /> },
            {
              path: ':clientId/profile/:pages',
              element: (
                <RoleBasedGuard
                  accessibleRoles={['Admin', user?.permission?.includes('client') && 'Employee']}
                >
                  <ClientProfile />
                </RoleBasedGuard>
              )
            },
            {
              path: ':clientId/profiles/:pages/:name/:phone',
              element: (
                <RoleBasedGuard
                  accessibleRoles={['Admin', user?.permission?.includes('client') && 'Employee']}
                >
                  <MusherClientProfile />
                </RoleBasedGuard>
              )
            },
            {
              path: 'list',
              element: (
                <RoleBasedGuard
                  accessibleRoles={['Admin', user?.permission?.includes('client') && 'Employee']}
                >
                  <ClientList />
                </RoleBasedGuard>
              )
            }
            // {
            //   path: 'new',
            //   element: (
            //     <RoleBasedGuard accessibleRoles={['Admin']}>
            //       <ClientCreate />
            //     </RoleBasedGuard>
            //   )
            // },
            // {
            //   path: ':name/edit',
            //   element: (
            //     <RoleBasedGuard accessibleRoles={['Admin']}>
            //       <ClientCreate />
            //     </RoleBasedGuard>
            //   )
            // }
          ]
        },

        {
          path: 'lawyer',
          children: [
            { element: <Navigate to="/dashboard/lawyer/list/?" replace /> },
            {
              path: ':lawyerId/profile/:page',
              element: (
                <RoleBasedGuard
                  accessibleRoles={['Admin', user?.permission?.includes('lawyers') && 'Employee']}
                >
                  <LawyerProfile />
                </RoleBasedGuard>
              )
            },
            {
              path: 'list',
              element: (
                <RoleBasedGuard
                  accessibleRoles={['Admin', user?.permission?.includes('lawyers') && 'Employee']}
                >
                  <LawyerList />
                </RoleBasedGuard>
              )
            }
            // {
            //   path: 'new',
            //   element: (
            //     <RoleBasedGuard accessibleRoles={['Admin']}>
            //       <LawyerCreate />
            //     </RoleBasedGuard>
            //   )
            // },
            // {
            //   path: ':name/edit',
            //   element: (
            //     <RoleBasedGuard accessibleRoles={['Admin']}>
            //       <LawyerCreate />
            //     </RoleBasedGuard>
            //   )
            // }
          ]
        },

        {
          path: 'calendar',
          element: (
            <RoleBasedGuard accessibleRoles={['Lawyer']}>
              <LawyerCalendar />
            </RoleBasedGuard>
          )
        },

        {
          path: 'availability/schedules',
          element: (
            <RoleBasedGuard accessibleRoles={['Lawyer']}>
              <LawyerAvailability />
            </RoleBasedGuard>
          )
        },

        {
          path: 'appointments',
          element: (
            <RoleBasedGuard accessibleRoles={['Lawyer']}>
              <LawyerAppointments />
            </RoleBasedGuard>
          )
        },
        {
          path: 'appointments/:appointmentId/details',
          element: <AppointmentDetails role={'lawyer'} />
        },
        {
          path: 'admin-appointments',
          children: [
            { element: <Navigate to="/dashboard/admin-appointments/list/?" replace /> },
            {
              path: 'list',
              element: (
                <RoleBasedGuard
                  accessibleRoles={[
                    'Admin',
                    user?.permission?.includes('appointments') && 'Employee'
                  ]}
                >
                  <AdminAppointments />
                </RoleBasedGuard>
              )
            },
            {
              path: ':appointmentId/details/:page',
              element: (
                <RoleBasedGuard
                  accessibleRoles={[
                    'Admin',
                    user?.permission?.includes('appointments') && 'Employee'
                  ]}
                >
                  <AppointmentDetails />
                </RoleBasedGuard>
              )
            }
          ]
        },

        {
          path: 'ramdan',
          element: (
            <RoleBasedGuard accessibleRoles={['Lawyer']}>
              <LawyerAppointments />
            </RoleBasedGuard>
          )
        },
        {
          path: 'ramdan/:appointmentId/details',
          element: <RamdanAppointmentDetails role={'lawyer'} />
        },
        {
          path: 'admin-ramdan',
          children: [
            { element: <Navigate to="/dashboard/admin-ramdan/list/?" replace /> },
            {
              path: 'list',
              element: (
                <RoleBasedGuard
                  accessibleRoles={[
                    'Admin',
                    user?.permission?.includes('shwra Al-Khair') && 'Employee'
                  ]}
                >
                  <RamdanAdminAppointments />
                </RoleBasedGuard>
              )
            },
            {
              path: ':appointmentId/details/:page',
              element: (
                <RoleBasedGuard
                  accessibleRoles={[
                    'Admin',
                    user?.permission?.includes('shwra Al-Khair') && 'Employee'
                  ]}
                >
                  <RamdanAppointmentDetails />
                </RoleBasedGuard>
              )
            }
          ]
        },

        {
          path: 'categories',
          children: [
            { element: <Navigate to="/dashboard/categories/list/?" replace /> },
            {
              path: 'list',
              element: (
                <RoleBasedGuard
                  accessibleRoles={[
                    'Admin',
                    user?.permission?.includes('categories') && 'Employee'
                  ]}
                >
                  <CategoriesList />
                </RoleBasedGuard>
              )
            },
            {
              path: ':categoryId/details/:page',
              element: (
                <RoleBasedGuard
                  accessibleRoles={[
                    'Admin',
                    user?.permission?.includes('categories') && 'Employee'
                  ]}
                >
                  <CategoryDetails />
                </RoleBasedGuard>
              )
            }
            // {
            //   path: 'new',
            //   element: (
            //     <RoleBasedGuard accessibleRoles={['Admin',user?.permission?.includes('categories') && 'Employee']}>
            //       <CategoryForm />
            //     </RoleBasedGuard>
            //   )
            // },
            // {
            //   path: ':categoryId/edit',
            //   element: (
            //     <RoleBasedGuard accessibleRoles={['Admin']}>
            //       <CategoryForm />
            //     </RoleBasedGuard>
            //   )
            // }
          ]
        },

        {
          path: 'packages',
          children: [
            { element: <Navigate to="/dashboard/packages/list" replace /> },
            {
              path: 'list',
              element: (
                <RoleBasedGuard
                  accessibleRoles={['Admin', user?.permission?.includes('packages') && 'Employee']}
                >
                  <PackagesList />
                </RoleBasedGuard>
              )
            },
            {
              path: ':packageId/details',
              element: (
                <RoleBasedGuard
                  accessibleRoles={['Admin', user?.permission?.includes('packages') && 'Employee']}
                >
                  <PackageDetails />
                </RoleBasedGuard>
              )
            }
            // {
            //   path: 'new',
            //   element: (
            //     <RoleBasedGuard accessibleRoles={['Admin']}>
            //       <PackageForm />
            //     </RoleBasedGuard>
            //   )
            // },
            // {
            //   path: ':packageId/edit',
            //   element: (
            //     <RoleBasedGuard accessibleRoles={['Admin']}>
            //       <PackageForm />
            //     </RoleBasedGuard>
            //   )
            // }
          ]
        },

        {
          path: 'features',
          children: [
            { element: <Navigate to="/dashboard/features/list/?" replace /> },
            {
              path: 'list',
              element: (
                <RoleBasedGuard
                  accessibleRoles={[
                    'Admin',
                    user?.permission?.includes('package features') && 'Employee'
                  ]}
                >
                  <FeaturesList />
                </RoleBasedGuard>
              )
            },
            {
              path: ':featureId/details/:page',
              element: (
                <RoleBasedGuard
                  accessibleRoles={[
                    'Admin',
                    user?.permission?.includes('package features') && 'Employee'
                  ]}
                >
                  <FeatureDetails />{' '}
                </RoleBasedGuard>
              )
            }
            // {
            //   path: 'new',
            //   element: (
            //     <RoleBasedGuard accessibleRoles={['Admin']}>
            //       <FeatureForm />
            //     </RoleBasedGuard>
            //   )
            // },
            // {
            //   path: ':featureId/edit',
            //   element: (
            //     <RoleBasedGuard accessibleRoles={['Admin']}>
            //       <FeatureForm />
            //     </RoleBasedGuard>
            //   )
            // }
          ]
        },
        {
          path: 'promotions',
          children: [
            { element: <Navigate to="/dashboard/promotions/list" replace /> },
            {
              path: 'list',
              element: (
                <RoleBasedGuard
                  accessibleRoles={[
                    'Admin',
                    user?.permission?.includes('discount codes') && 'Employee'
                  ]}
                >
                  <PromotionsList />
                </RoleBasedGuard>
              )
            }
            // {
            //   path: ':promotionId/details',
            //   element: (
            //     <RoleBasedGuard accessibleRoles={['Admin']}>
            //       <PromotionDetails />{' '}
            //     </RoleBasedGuard>
            //   )
            // },
            // {
            //   path: 'new',
            //   element: (
            //     <RoleBasedGuard accessibleRoles={['Admin']}>
            //       <PromotionForm />
            //     </RoleBasedGuard>
            //   )
            // },
            // {
            //   path: ':promotionId/edit',
            //   element: (
            //     <RoleBasedGuard accessibleRoles={['Admin']}>
            //       <PromotionForm />
            //     </RoleBasedGuard>
            //   )
            // }
          ]
        },
        {
          path: 'complaints',
          children: [
            { element: <Navigate to="/dashboard/complaints/list" replace /> },
            {
              path: 'list',
              element: (
                <RoleBasedGuard
                  accessibleRoles={[
                    'Admin',
                    user?.permission?.includes('complaints and suggestions') && 'Employee'
                  ]}
                >
                  <ComplaintsList />
                </RoleBasedGuard>
              )
            },
            {
              path: ':complaintId/details',
              element: (
                <RoleBasedGuard
                  accessibleRoles={[
                    'Admin',
                    user?.permission?.includes('complaints and suggestions') && 'Employee'
                  ]}
                >
                  <ComplaintsDetails />
                </RoleBasedGuard>
              )
            }
          ]
        },

        // News Letter
        {
          path: 'Newsletter',
          children: [
            { element: <Navigate to="/dashboard/Newsletter/list" replace /> },
            {
              path: 'list',
              element: (
                <RoleBasedGuard
                  accessibleRoles={[
                    'Admin',
                    user?.permission?.includes('News Letter') && 'Employee'
                  ]}
                >
                  <NewsLetterSubscribers />
                </RoleBasedGuard>
              )
            }
          ]
        },
        //Newly Added for Monthly Reports
        // Event Managment Screen
        {
          path: 'Event_Form_Data',
          children: [
            { element: <Navigate to="/dashboard/Event_Form_Data/list" replace /> },
            {
              path: 'list',
              element: (
                <RoleBasedGuard
                  accessibleRoles={[
                    'Admin',
                    user?.permission?.includes('Event_Form_Data') && 'Employee'
                  ]}
                >
                  <EventManagemntScreen />
                </RoleBasedGuard>
              )
            }
          ]
        },
        // End
        {
          path: 'report',
          children: [
            { element: <Navigate to="/dashboard/report/list" replace /> },
            {
              path: 'list',
              element: (
                <RoleBasedGuard
                  accessibleRoles={[
                    'Admin',
                    user?.permission?.includes('monthly report') && 'Employee'
                  ]}
                >
                  <MonthlyReport />
                </RoleBasedGuard>
              )
            }
          ]
        },
        {
          path: 'user',
          children: [
            { element: <Navigate to="/dashboard/user/profile" replace /> },
            { path: 'profile', element: <UserProfile /> },
            { path: 'account', element: <UserAccount /> },
            { path: 'configurations/new', element: <ConfigurationForm /> },
            { path: 'configurations/edit', element: <ConfigurationForm /> }
          ]
        },
        //     New Added For Employees
        {
          path: 'Employee',
          children: [
            { element: <Navigate to="/dashboard/Employee/list" replace /> },
            {
              path: 'list',
              element: (
                <RoleBasedGuard
                  accessibleRoles={['Admin', user?.permission?.includes('employees') && 'Employee']}
                >
                  <EmployeeList />
                </RoleBasedGuard>
              )
            },
            {
              path: ':employeeId/details',
              element: (
                <RoleBasedGuard
                  accessibleRoles={['Admin', user?.permission?.includes('employees') && 'Employee']}
                >
                  <EmployeeDetails />
                </RoleBasedGuard>
              )
            }
          ]
        },

        {
          path: 'permission',
          children: [
            { element: <Navigate to="/dashboard/permission/list" replace /> },
            {
              path: 'list',
              element: (
                <RoleBasedGuard
                  accessibleRoles={[
                    'Admin',
                    user?.permission?.includes('permissions') && 'Employee'
                  ]}
                >
                  <PermissionList />
                </RoleBasedGuard>
              )
            },
            {
              path: ':permissionId/details',
              element: (
                <RoleBasedGuard
                  accessibleRoles={[
                    'Admin',
                    user?.permission?.includes('permissions') && 'Employee'
                  ]}
                >
                  <PermissionDetails />
                </RoleBasedGuard>
              )
            }
          ]
        },

        //     New Added For Musher
        {
          path: 'Musher',
          children: [
            { element: <Navigate to="/dashboard/musher/list/?/0" replace /> },
            {
              path: ':clientId/musher/:pages',
              element: (
                <RoleBasedGuard
                  accessibleRoles={['Admin', user?.permission?.includes('adviser') && 'Employee']}
                >
                  <MusherClientProfile />
                </RoleBasedGuard>
              )
            },
            {
              path: 'list',
              element: (
                <RoleBasedGuard
                  accessibleRoles={['Admin', user?.permission?.includes('adviser') && 'Employee']}
                >
                  <MusherList />
                </RoleBasedGuard>
              )
            }
          ]
        },
        //     New Added For B2b
        {
          path: 'B2b',
          children: [
            { element: <Navigate to="/dashboard/b2b/list/?/0" replace /> },
            {
              path: ':b2bId/b2b/:pages',
              element: (
                <RoleBasedGuard
                  accessibleRoles={['Admin', user?.permission?.includes('companies') && 'Employee']}
                >
                  <B2bDetails />
                </RoleBasedGuard>
              )
            },
            {
              path: 'list',
              element: (
                <RoleBasedGuard
                  accessibleRoles={['Admin', user?.permission?.includes('companies') && 'Employee']}
                >
                  <B2bList />
                </RoleBasedGuard>
              )
            }
          ]
        },

        // AddingB2b Customer
        {
          path: 'Customerb2b',
          children: [
            { element: <Navigate to="/dashboard/Customerb2b/list" replace /> },

            {
              path: 'list',
              element: (
                <RoleBasedGuard
                  accessibleRoles={[
                    'Admin',
                    user?.permission?.includes('B2B Customers') && 'Employee'
                  ]}
                >
                  <CustomerListB2B />
                </RoleBasedGuard>
              )
            },
            {
              path: 'details/:userId', // Change this to a relative path
              element: (
                <RoleBasedGuard
                  accessibleRoles={[
                    'Admin',
                    user?.permission?.includes('B2B Customers') && 'Employee'
                  ]}
                >
                  <CustomerListB2BDetails />
                </RoleBasedGuard>
              )
            }
          ]
        },

        // adding MasterCategory
        {
          path: 'MasterCategory',
          children: [
            { element: <Navigate to="/dashboard/MasterCategory/list" replace /> },

            {
              path: 'list',
              element: (
                <RoleBasedGuard
                  accessibleRoles={[
                    'Admin',
                    user?.permission?.includes('Master Category') && 'Employee'
                  ]}
                >
                  <MasterCategoryList />
                </RoleBasedGuard>
              )
            },
            {
              path: 'details/:Master_Category',
              element: (
                <RoleBasedGuard
                  accessibleRoles={[
                    'Admin',
                    user?.permission?.includes('Master Category') && 'Employee'
                  ]}
                >
                  <MasterCategoriesDetails />
                </RoleBasedGuard>
              )
            }
          ]
        },
        // adding News Managment
        {
          path: 'news',
          children: [
            { element: <Navigate to="/dashboard/news/details" replace /> },

            {
              path: 'list',
              element: (
                <RoleBasedGuard
                  accessibleRoles={['Admin', user?.permission?.includes('News') && 'Employee']}
                >
                  <NewsManagments />
                </RoleBasedGuard>
              )
            },
            {
              path: 'add',
              element: (
                <RoleBasedGuard
                  accessibleRoles={['Admin', user?.permission?.includes('News') && 'Employee']}
                >
                  <NewsManagmentsCreate />
                </RoleBasedGuard>
              )
            },
            {
              path: 'edit/:id',
              element: (
                <RoleBasedGuard
                  accessibleRoles={['Admin', user?.permission?.includes('News') && 'Employee']}
                >
                  <NewsManagmentsUpdate />
                </RoleBasedGuard>
              )
            },
            {
              path: 'details/:id',
              element: (
                <RoleBasedGuard
                  accessibleRoles={['Admin', user?.permission?.includes('News') && 'Employee']}
                >
                  <NewsManagmentsDetails />
                </RoleBasedGuard>
              )
            }

            // {
            //   path: 'details/:Master_Category', // Change this to a relative path
            //   element: (
            //     <RoleBasedGuard
            //       accessibleRoles={[
            //         'Admin',
            //         user?.permission?.includes('customer details') && 'Employee'
            //       ]}
            //     >
            //       <MasterCategoriesDetails />
            //     </RoleBasedGuard>
            //   )
            // }
          ]
        },

        {
          path: 'questions',
          children: [
            { element: <Navigate to="/dashboard/questions/?/?" replace /> },
            {
              path: 'list',
              element: (
                <RoleBasedGuard
                  accessibleRoles={['Admin', user?.permission?.includes('questions') && 'Employee']}
                >
                  <QuestionsDetail />
                </RoleBasedGuard>
              )
            }
          ]
        },
        {
          path: 'blogs',
          children: [
            { element: <Navigate to="/dashboard/blogs/list" replace /> },
            {
              path: 'list',
              element: (
                <RoleBasedGuard
                  accessibleRoles={['Admin', user?.permission?.includes('blogs') && 'Employee']}
                >
                  <BlogsList />
                </RoleBasedGuard>
              )
            },
            {
              path: 'edit',
              element: (
                <RoleBasedGuard
                  accessibleRoles={['Admin', user?.permission?.includes('blogs') && 'Employee']}
                >
                  <CreateEditBlog />
                </RoleBasedGuard>
              )
            },
            {
              path: ':blogId/details',
              element: (
                <RoleBasedGuard
                  accessibleRoles={['Admin', user?.permission?.includes('blogs') && 'Employee']}
                >
                  <BlogDetail />
                </RoleBasedGuard>
              )
            }
          ]
        },
        {
          path: 'scheduleDemo',
          children: [
            { element: <Navigate to="/dashboard/scheduleDemo/list" replace /> },
            {
              path: 'list',
              element: (
                <RoleBasedGuard
                  accessibleRoles={[
                    'Admin',
                    user?.permission?.includes('schedule demo') && 'Employee'
                  ]}
                >
                  <ScheduleDemoList />
                </RoleBasedGuard>
              )
            },
            {
              path: '/dashboard/scheduleDemo/details',
              element: (
                <RoleBasedGuard
                  accessibleRoles={[
                    'Admin',
                    user?.permission?.includes('schedule demo') && 'Employee'
                  ]}
                >
                  <ScheduleDemoDetailsList />
                </RoleBasedGuard>
              )
            }
          ]
        }
      ]
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> }
      ]
    },
    { path: '*', element: <Navigate to="/404" replace /> },
    { path: '/consultation-form', element: <Consultation /> }
  ]);
}
// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import('../pages/authentication/Login')));
const Register = Loadable(lazy(() => import('../pages/authentication/Register')));
const ForgetPassword = Loadable(lazy(() => import('../pages/authentication/ForgetPassword')));
const ProfileComplete = Loadable(lazy(() => import('../pages/authentication/ProfileComplete')));
const VerifyCode = Loadable(lazy(() => import('../pages/authentication/VerifyCode')));
const ResetPassword = Loadable(lazy(() => import('../pages/authentication/ResetPassword')));
const Consulation = Loadable(lazy(() => import('../pages/Consultation')));

// account
const AccountPending = Loadable(lazy(() => import('../pages/authentication/AccountPending')));
const AccountBlocked = Loadable(lazy(() => import('../pages/authentication/AccountBlocked')));

// Dashboard
const UserProfile = Loadable(lazy(() => import('../pages/dashboard/UserProfile')));
const UserAccount = Loadable(lazy(() => import('../pages/dashboard/UserAccount')));

//-------------------------------------------------------------------------------------/
const RequestCreate = Loadable(lazy(() => import('../pages/dashboard/RequestCreate')));
const RequestDetail = Loadable(lazy(() => import('../pages/dashboard/RequestDetail')));
const MasterRequestDetail = Loadable(lazy(() => import('../pages/dashboard/MasterRequestDetail')));

const RequestListClient = Loadable(lazy(() => import('../pages/dashboard/RequestListClient')));
const RequestListLawyer = Loadable(lazy(() => import('../pages/dashboard/RequestListLawyer')));
const RequestListAdmin = Loadable(lazy(() => import('../pages/dashboard/RequestListAdmin')));

//--------------------------------------------------------------------------------------
const LawyerProfile = Loadable(
  lazy(() => import('../pages/dashboard/lawyersManagement/LawyerProfile'))
);
const LawyerList = Loadable(lazy(() => import('../pages/dashboard/lawyersManagement/LawyerList')));
const LawyerCreate = Loadable(
  lazy(() => import('../pages/dashboard/lawyersManagement/LawyerCreate'))
);
const LawyerCalendar = Loadable(
  lazy(() => import('../pages/dashboard/lawyersManagement/LawyerCalendar'))
);
const LawyerAvailability = Loadable(
  lazy(() => import('../pages/dashboard/lawyersManagement/LawyerAvailability'))
);
const LawyerAppointments = Loadable(
  lazy(() => import('../pages/dashboard/lawyersManagement/LawyerAppointments'))
);

//--------------------------------------------------------------------------------------
const AdminAppointments = Loadable(
  lazy(() => import('../pages/dashboard/Appointment Managment/AdminAppointments'))
);
const AppointmentDetails = Loadable(
  lazy(() => import('../pages/dashboard/Appointment Managment/AppointmentDetails'))
);

const RamdanAdminAppointments = Loadable(
  lazy(() => import('../pages/dashboard/Ramdan Managment/RamdanAdminAppointments'))
);
const RamdanAppointmentDetails = Loadable(
  lazy(() => import('../pages/dashboard/Ramdan Managment/RamdanAppointmentDetails'))
);

//--------------------------------------------------------------------------------------

//--------------------------------------------------------------------------------------
const CategoriesList = Loadable(
  lazy(() => import('../pages/dashboard/categoriesManagement/CategoriesList'))
);
const CategoryDetails = Loadable(
  lazy(() => import('../pages/dashboard/categoriesManagement/CategoryDetails'))
);
const CategoryForm = Loadable(
  lazy(() => import('../pages/dashboard/categoriesManagement/CategoryForm'))
);

//--------------------------------------------------------------------------------------

//--------------------------------------------------------------------------------------
const PackagesList = Loadable(
  lazy(() => import('../pages/dashboard/packagessManagement/PackagesList'))
);
const PackageDetails = Loadable(
  lazy(() => import('../pages/dashboard/packagessManagement/PackageDetails'))
);
const PackageForm = Loadable(
  lazy(() => import('../pages/dashboard/packagessManagement/PackageForm'))
);

//--------------------------------------------------------------------------------------

const ClientProfile = Loadable(
  lazy(() => import('../pages/dashboard/ClientsManagement/ClientProfile'))
);
const MusherClientProfile = Loadable(
  lazy(() => import('../pages/dashboard/musherManagement/MusherClientProfile'))
);

const ClientList = Loadable(lazy(() => import('../pages/dashboard/ClientsManagement/ClientList')));
const ClientCreate = Loadable(
  lazy(() => import('../pages/dashboard/ClientsManagement/ClientCreate'))
);

// Main
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));

const ComplaintsList = Loadable(
  lazy(() => import('../pages/dashboard/complaintsManagement/ComplaintsList'))
);

//Newly Added for Monthly Reports

const MonthlyReport = Loadable(
  lazy(() => import('../pages/dashboard/MonthlyReport/MonthlyReport'))
);
const ComplaintsDetails = Loadable(
  lazy(() => import('../pages/dashboard/complaintsManagement/ComplaintsDetails'))
);

const PromotionForm = Loadable(
  lazy(() => import('../pages/dashboard/promotionsManagement/PromotionsForm'))
);
// const PromotionDetails = Loadable(
//   lazy(() => import('../pages/dashboard/promotionsManagement/PromotionDetails'))
// );
const PromotionsList = Loadable(
  lazy(() => import('../pages/dashboard/promotionsManagement/PromotionsList'))
);

const FeatureForm = Loadable(
  lazy(() => import('../pages/dashboard/featuresManagement/FeatureForm'))
);
const FeatureDetails = Loadable(
  lazy(() => import('../pages/dashboard/featuresManagement/FeatureDetails'))
);
const FeaturesList = Loadable(
  lazy(() => import('../pages/dashboard/featuresManagement/FeaturesList'))
);

//New Added For Employees

const EmployeeList = Loadable(
  lazy(() => import('../pages/dashboard/EmployeeManagement/EmployeeList'))
);

const EmployeeDetails = Loadable(
  lazy(() => import('../pages/dashboard/EmployeeManagement/EmployeeDetails'))
);

const PermissionList = Loadable(
  lazy(() => import('../pages/dashboard/PermissionManagement/PermissionList'))
);

const PermissionDetails = Loadable(
  lazy(() => import('../pages/dashboard/PermissionManagement/PermissionDetail'))
);

const BlogsList = Loadable(lazy(() => import('../pages/dashboard/blogsManagement/BlogsList')));

const CreateEditBlog = Loadable(
  lazy(() => import('../pages/dashboard/blogsManagement/CreateEditBlog'))
);

const BlogDetail = Loadable(lazy(() => import('../pages/dashboard/blogsManagement/BlogDetail')));

const ScheduleDemoList = Loadable(
  lazy(() => import('../pages/dashboard/scheduleDemoManagement/ScheduleDemoList'))
);

const ScheduleDemoDetailsList = Loadable(
  lazy(() => import('../pages/dashboard/scheduleDemoManagement/ScheduleDemoProfileDetails'))
);

const CustomerListB2B = Loadable(
  lazy(() => import('../pages/dashboard/B2BCustomer/B2BCustomersLists'))
);
const CustomerListB2BDetails = Loadable(
  lazy(() => import('../pages/dashboard/B2BCustomer/B2BCustomerDetails'))
);
const MasterCategoryList = Loadable(
  lazy(() => import('../pages/dashboard/MasterCategories/MasterCategoriesList'))
);
const MasterCategoriesDetails = Loadable(
  lazy(() => import('../pages/dashboard/MasterCategories/MasterCategoriesDetails'))
);
const NewsManagments = Loadable(lazy(() => import('../pages/dashboard/newsManagment/Page')));
const NewsManagmentsCreate = Loadable(
  lazy(() => import('../pages/dashboard/newsManagment/Components/CreateNewsForm'))
);
const NewsManagmentsUpdate = Loadable(
  lazy(() => import('../pages/dashboard/newsManagment/Components/EditNews'))
);
const NewsManagmentsDetails = Loadable(
  lazy(() => import('../pages/dashboard/newsManagment/Components/NewsDetails'))
);
const NewsLetterSubscribers = Loadable(
  lazy(() => import('../pages/dashboard/NewsLetterSubscribers/NewsLetter'))
);
const EventManagemntScreen = Loadable(
  lazy(() => import('../pages/dashboard/EventFormSubmission/page'))
);
