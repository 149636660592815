import * as Sentry from '@sentry/react';
import { find } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
// utils
import axios from '../../utils/axios';
import { ReporttState } from '../../@types/report';
import moment from 'moment';
import { getMonthName } from 'utils/formatNumber';
// @types

// ----------------------------------------------------------------------

const initialState: ReporttState = {
  isLoading: false,
  isAssigning: false,
  error: false,
  monthlyReport: [],
  monthlyReportFiltered: [],
  approvedLawyers: [],
  AppointmentCategoryAssignments: [],
  AppointmentsByMonthDetail: [],
  AppointmentsByMonthDetailApp: [],
  TotalIncomes: [],
  TotalIncomesByYear: [],
  GetNewUserappoinment: []
};

const slice = createSlice({
  name: 'report',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    setErrorDefault(state) {
      state.error = false;
    },

    // START LOADING
    startAssignment(state) {
      state.isAssigning = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    //Newly Added request for Monthly Reports

    getMonthlyReportSuccess(state, action) {
      state.isLoading = false;
      state.monthlyReport = action.payload;
    },
    getMonthlyReportFilteredSuccess(state, action) {
      state.isLoading = false;
      state.monthlyReportFiltered = action.payload;
    },
    getApprovedLawyerListSuccess(state, action) {
      state.isLoading = false;
      state.approvedLawyers = action.payload;
    },

    getAppointmentCategoryAssignmentsSuccess(state, action) {
      state.isLoading = false;
      state.AppointmentCategoryAssignments = action.payload;
    },

    getTotalIncomeSuccess(state, action) {
      state.isLoading = false;
      state.TotalIncomes = action.payload;
    },

    getTotalIncomeByYearSuccess(state, action) {
      state.isLoading = false;
      state.TotalIncomesByYear = action.payload;
    },

    getAppointmentsByMonthDetailSuccess(state, action) {
      state.isLoading = false;
      state.AppointmentsByMonthDetail = action.payload.result;
      state.AppointmentsByMonthDetailApp = action.payload.result.appointments.map(
        (
          item: {
            [x: string]: any;
            month: any;
          },
          ind: number
        ) => {
          let Match = action.payload.result?.appointments?.find(
            (appointment: { month: any; year: number }) => appointment?.month === ind + 1
          );
          if (Match) {
            return {
              month: getMonthName(item.month),
              total: item.total,
              year: item.year,
              index: `${item.month}-${item.year.toString()?.slice(2)}`
            };
          } else {
            return {
              month: getMonthName(item.month),
              total: item.total,
              year: item.year,
              index: `${item.month}-${item.year.toString()?.slice(2)}`
            };
          }
        }
      );
    },
    getGetNewUserappoinment(state, action) {
      state.GetNewUserappoinment = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
// export const { } = slice.actions;

// ----------------------------------------------------------------------
//Newly Added request for Monthly Reports

export function getMonthlyReportList(Year: number, Month: number, lawyerId: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/Report/LawyerCompletedAppointmentsPackageWise', {
        params: {
          year: Year,
          month: Month,
          LawyerId: lawyerId
        }
      });
      if (response?.status === 200 && response?.data?.result?.status === true) {
        dispatch(slice.actions.getMonthlyReportSuccess(response?.data?.result?.result));
      }
    } catch (error) {
      Sentry.captureException(error);
      dispatch(slice.actions.hasError(true));
    }
  };
}

export function getApprovedLawyerList() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/Lawyers', { params: { page: 1 } });
      dispatch(slice.actions.getApprovedLawyerListSuccess(response.data?.result?.lawyersDtos));
    } catch (error) {
      Sentry.captureException(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function GetNewUserappoinmentDetails(fromDate: string, toDate: string) {
  return async (dispatch: any) => {
    dispatch(slice.actions.startLoading());
    try {
      // Append the date parameters to the URL
      const response = await axios.get('/Report/GetNewUserAppointmentAndRequestKpi', {
        params: {
          StartDate: fromDate, // Pass StartDate as query parameter
          EndDate: toDate,     // Pass EndDate as query parameter
        },
      });
      dispatch(slice.actions.getGetNewUserappoinment(response.data?.result?.result));
    } catch (error) {
      Sentry.captureException(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}


export function getAppointmentCategoryAssignments() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/Appointments/AppointmentCategoryAssignments');

      dispatch(slice.actions.getAppointmentCategoryAssignmentsSuccess(response.data.result));
    } catch (error) {
      Sentry.captureException(error);
      dispatch(slice.actions.hasError(true));
    }
  };
}

export function getTotalIncome() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/Report/TotalIncome');
      dispatch(slice.actions.getTotalIncomeSuccess(response.data.result.result));
    } catch (error) {
      Sentry.captureException(error);
      dispatch(slice.actions.hasError(true));
    }
  };
}

export function getTotalIncomeByYear(year: number) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/Report/TotalIncome?Year=${year}`);
      dispatch(slice.actions.getTotalIncomeByYearSuccess(response.data.result.result));
    } catch (error) {
      Sentry.captureException(error);
      dispatch(slice.actions.hasError(true));
    }
  };
}

export function getAppointmentsByMonthDetail(from?: string, to?: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        from && to
          ? `/Report/AppointmentsByMonthDetail?from=${from}&to=${to}`
          : '/Report/AppointmentsByMonthDetail'
      );

      dispatch(slice.actions.getAppointmentsByMonthDetailSuccess(response.data.result));
    } catch (error) {
      Sentry.captureException(error);
      dispatch(slice.actions.hasError(true));
    }
  };
}

export function getAppointmentAndRequestListWithInvoice(referenceType: number) {
  return async (dispatch: any) => {
    try {
      const response = await axios.get('/Report/GetInvoicesStatusWiseByReference', {
        params: {
          ReferenceType: referenceType,
          PageSize: 100,
          PageNumer: 1
        }
      });
      if (response?.data?.status) {
        return response?.data?.result?.data;
      }
      return [];
    } catch (error) {
      Sentry.captureException(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

//--------------------------------------------------------------------------------------------
