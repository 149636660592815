import * as Sentry from '@sentry/react';
import { find } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
// utils
import axios from '../../utils/axios';
import { B2BCustomers, EmployeeState } from '../../@types/employee';
// @types

// ----------------------------------------------------------------------

const initialState: B2BCustomers = {
  isLoading: false,
  isAssigning: false,
  error: false,
  EmployeeList: [],
  Employee: {},
  createEmployeeStatus: {},
  updateEmployeeStatus: {},
  deleteEmployeeStatus: {},
  changeEmployeeStatusSuccess: {},
  employeeAvailabilityStatus: {},
  ShowSuccessMessage: [],
  isUpdate: null
};

const slice = createSlice({
  name: 'employee',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    setErrorDefault(state) {
      state.error = false;
    },

    // START LOADING
    startAssignment(state) {
      state.isAssigning = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    //Newly Added request for Monthly Reports

    getEmployeeListSuccess(state, action) {
      state.isLoading = false;
      state.EmployeeList = action.payload;
    },
    getEmployeeSuccess(state, action) {
      state.isLoading = false;
      state.Employee = action.payload;
    },
    createEmployeeSuccess(state, action) {
      state.isLoading = false;
      state.createEmployeeStatus = action.payload;
    },
    deleteEmployeeSuccess(state, action) {
      state.isLoading = false;
      state.deleteEmployeeStatus = action.payload;
    },
    updateEmployeeSuccess(state, action) {
      state.isLoading = false;
      state.updateEmployeeStatus = action.payload;
    },
    changeEmployeeStatusSuccess(state, action) {
      // state.isLoading = false;
      state.changeEmployeeStatusSuccess = action.payload;
    },
    changeEmployeeAvailabilityStatusSuccess(state, action) {
      // state.isLoading = false;
      state.employeeAvailabilityStatus = action.payload;
    },
    setShowSuccessMessage(state, actions) {
      state.ShowSuccessMessage = actions.payload;
    },
    setisUpdate(state, action) {
      state.isUpdate = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
// export const { } = slice.actions;

// ----------------------------------------------------------------------
//Newly Added request for Monthly Reports

export function getB2BCustomersList(
  limit?: number,
  page?: number,
  orderBy?: string,
  sort?: string,
  Status?: number,
  SearchTerm?: string
) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/AppAdmin/GetAllB2BCustomers', {
        params: {
          limit: limit, // Updated parameter
          page: page,
          orderBy: orderBy, // New parameter
          sort: sort, // New parameter
          Status: Status,
          SearchTerm: SearchTerm
        }
      });

      dispatch(slice.actions.getEmployeeListSuccess(response.data.result));
    } catch (error) {
      Sentry.captureException(error);
      dispatch(slice.actions.hasError(true));
    }
  };
}

export function getB2BEmployee(B2BCustomerId?: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/AppAdmin/GetB2BCustomerById/${B2BCustomerId}`);

      dispatch(slice.actions.getEmployeeSuccess(response.data.result));
    } catch (error) {
      Sentry.captureException(error);
      dispatch(slice.actions.hasError(true));
    }
  };
}

export function createEmployee(data: FormData, callBack: Function) {
  return async (dispatch: any) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`/AppAdmin/AdminB2BCustomerCreate`, data);
      if (response?.data?.status === false) {
        callBack(response);
        // On error, pass the errors array
        dispatch(
          slice.actions.createEmployeeSuccess({ status: 'error', error: response?.data?.errors })
        );
      } else {
        callBack(response);
        // On success, pass the success message and user details
        dispatch(
          slice.actions.createEmployeeSuccess({
            status: 'success',
            message: response?.data?.result[0], // This contains the success message
            userData: response?.data?.result[1] // User data can be used elsewhere if needed
          })
        );
      }
    } catch (error) {
      Sentry.captureException(error);
      dispatch(slice.actions.hasError(error));
      dispatch(slice.actions.createEmployeeSuccess({ status: 'error', error: error }));
    }
  };
}

export function updateEmployee(data: FormData, callBack: Function) {
  return async (dispatch: any) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put(`/AppAdmin/AdminB2BCustomerUpdate`, data);

      if (response?.data?.status === false) {
        callBack(response);
        // On error, dispatch the errors array
        dispatch(
          slice.actions.updateEmployeeSuccess({ status: 'error', error: response?.data?.errors })
        );
      } else {
        callBack(response);

        // On success, pass the success message and any updated data
        dispatch(
          slice.actions.updateEmployeeSuccess({
            status: 'success',
            message: response?.data?.result[0], // Success message
            userData: response?.data?.result[1] // Updated user data
          })
        );
      }
    } catch (error) {
      Sentry.captureException(error);
      dispatch(slice.actions.hasError(error));
      dispatch(slice.actions.updateEmployeeSuccess({ status: 'error', error: error }));
    }
  };
}

export function deleteEmployee(id: string | number) {
  return async (dispatch: any) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.delete(`/AppAdmin/Delete/${id}`);

      // Handle success
      if (response.status === 200 && response?.data?.status !== false) {
        dispatch(
          slice.actions.deleteEmployeeSuccess({
            status: 'success',
            message: response?.data?.result || 'Employee deleted successfully' // Use the success message from the API response
          })
        );
      } else {
        // Handle server-reported errors (e.g., validation issues)
        dispatch(
          slice.actions.deleteEmployeeSuccess({
            status: 'error',
            error: response?.data?.errors || 'An error occurred' // Error from API
          })
        );
      }
    } catch (error: any) {
      // Check for 400 or 404 errors
      if (error.response?.status === 400 || error.response?.status === 404) {
        dispatch(
          slice.actions.deleteEmployeeSuccess({
            status: 'error',
            error: error.response?.data?.errors || 'Invalid request' // Handle validation error
          })
        );
      } else {
        // Handle other errors and report to Sentry
        Sentry.captureException(error);
        dispatch(slice.actions.hasError(error));
        dispatch(slice.actions.deleteEmployeeSuccess({ status: 'error', error: error.message }));
      }
    }
  };
}

export function changeEmployeeStatus(id: String, status: number) {
  return async () => {
    // dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put(`/Employee/UpdateEmployeeStatus`, null, {
        params: {
          EmployeeId: id,
          Status: status
        }
      });
      if (response?.data?.status === false) {
        dispatch(
          slice.actions.changeEmployeeStatusSuccess({ status: 'error', error: response?.data })
        );
      } else
        dispatch(
          slice.actions.changeEmployeeStatusSuccess({
            status: 'success',
            ...response?.data?.result
          })
        );
    } catch (error) {
      Sentry.captureException(error);
      dispatch(slice.actions.hasError(error));
      dispatch(slice.actions.changeEmployeeStatusSuccess({ status: 'error', error: error }));
    }
    return;
  };
}

export function markEmployeeAvailability(id: String, isAvailable: boolean) {
  return async () => {
    // dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put(`/Employee/MarkAvailability`, null, {
        params: {
          EmployeeId: id,
          IsAvailable: isAvailable
        }
      });
      if (response?.data?.status === false) {
        dispatch(
          slice.actions.changeEmployeeAvailabilityStatusSuccess({
            status: 'error',
            error: response?.data
          })
        );
      } else
        dispatch(
          slice.actions.changeEmployeeAvailabilityStatusSuccess({
            status: 'success',
            ...response?.data?.result
          })
        );
    } catch (error) {
      Sentry.captureException(error);
      dispatch(slice.actions.hasError(error));
      dispatch(
        slice.actions.changeEmployeeAvailabilityStatusSuccess({ status: 'error', error: error })
      );
    }
    return;
  };
}

export function setUpdateEmployeeStatus(data: any) {
  return dispatch(slice.actions.updateEmployeeSuccess(data));
}

export function setCreateEmployeeStatus(data: any) {
  return dispatch(slice.actions.createEmployeeSuccess(data));
}

export function setDeleteEmployeeStatus(data: any) {
  return dispatch(slice.actions.deleteEmployeeSuccess(data));
}
export function SetIsUpdatedCustomer(data: any) {
  return dispatch(slice.actions.setisUpdate(data));
}

//--------------------------------------------------------------------------------------------
