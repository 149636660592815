import * as Sentry from '@sentry/react';
import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
// utils
import { BLOGS_URL } from '../../utils/axios';
import { getToken } from '../../utils/tokenService';
import axios from 'axios';

const initialState: any = {
  isLoading: null,
  error: null,
  NewsData: [],
  NewsAdded: [],
  UpdateNews: [],
  DeleteNews: [],
  DetailsNews: [],
  NewsLetterSubscribersData: [],
  NewsLanguage: 'ar-SA',
  EventManagementData: []
};

const slice = createSlice({
  name: 'news',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET ALL NEWS
    getAllNewsSuccess(state, action) {
      state.isLoading = false;
      state.error = null;
      state.NewsData = action.payload;
    },
    setNewsAdded(state, action) {
      state.isLoading = false;
      state.error = null;
      state.NewsAdded = action.payload;
    },
    setUpdateNews(state, action) {
      state.isLoading = false;
      state.error = null;
      state.UpdateNews = action.payload;
    },
    setDeleteNews(state, action) {
      state.isLoading = false;
      state.error = null;
      state.DeleteNews = action.payload;
    },
    setDetailsNews(state, action) {
      state.isLoading = false;
      state.error = null;
      state.DetailsNews = action.payload;
    },
    setGetAllNewsLetterSubscribers(state, action) {
      state.isLoading = false;
      state.error = null;
      state.NewsLetterSubscribersData = action.payload;
    },
    setNewsLanguage(state, action) {
      state.isLoading = false;
      state.error = null;
      state.NewsLanguage = action.payload;
    },
    setEventManagementData(state, action) {
      state.isLoading = false;
      state.error = null;
      state.EventManagementData = action.payload;
    }
  }
});

export function getNews(
  orderBy: string,
  page: number,
  pageSize: number,
  locale: string,
  callback: Function
) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${BLOGS_URL}/News/GetAllNewsAsync`, {
        params: {
          orderBy,
          page,
          pageSize
        },
        headers: {
          'Accept-Language': locale
        }
      });
      dispatch(slice.actions.getAllNewsSuccess(response?.data));
      callback(response);
    } catch (error) {
      Sentry.captureException(error);
      dispatch(slice.actions.hasError(error));
      dispatch(slice.actions.hasError({ status: 'error', error: error }));
    }
  };
}
export function getNewsLetterSubscribers(orderBy: string, page: number, pageSize: number) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `${BLOGS_URL}/Subscribers/GetAllSubscribersAsync?page=${page}&pageSize=${pageSize}`,
        {
          params: {
            orderBy,
            page,
            pageSize
          }
        }
      );
      dispatch(slice.actions.setGetAllNewsLetterSubscribers(response?.data));
    } catch (error) {
      Sentry.captureException(error);
      dispatch(slice.actions.hasError(error));
      dispatch(slice.actions.hasError({ status: 'error', error: error }));
    }
  };
}
export function getEventFormSubmissionData(orderBy: string, page: number, pageSize: number) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `${BLOGS_URL}/CompanyEvent/GetAllCompanyEventsAsync?page=${page}&pageSize=${pageSize}`,
        {
          params: {
            orderBy,
            page,
            pageSize
          }
        }
      );
      dispatch(slice.actions.setEventManagementData(response?.data));
    } catch (error) {
      Sentry.captureException(error);
      dispatch(slice.actions.hasError(error));
      dispatch(slice.actions.hasError({ status: 'error', error: error }));
    }
  };
}

export function createNewsPost(data: any, callback: Function, language: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      let authToken = await getToken();
      const response = await axios.post(`${BLOGS_URL}/News/CreateNewsAsync`, data, {
        headers: {
          Authorization: `Bearer ${authToken}`,
          'Content-Type': 'application/json',
          accept: 'text/plain',
          'Accept-Language': language
        }
      });
      dispatch(slice.actions.setNewsAdded({ STATUS: true, ...response?.data?.result }));
      callback(response);
    } catch (error) {
      Sentry.captureException(error);
      dispatch(slice.actions.hasError(error));
      dispatch(slice.actions.setNewsAdded({ STATUS: false, error: error }));
    }
  };
}

export function updateNewsPost(data: any, language: string, callback: Function) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      let authToken = await getToken();
      const response = await axios.put(`${BLOGS_URL}/News/UpdateNewsAsync`, data, {
        headers: {
          Authorization: `Bearer ${authToken}`,
          'Content-Type': 'application/json',
          accept: 'text/plain',
          'Accept-Language': language
        }
      });
      dispatch(slice.actions.setUpdateNews({ STATUS: true, ...response?.data?.result }));
      callback(response);
    } catch (error) {
      Sentry.captureException(error);
      dispatch(slice.actions.hasError(error));
      dispatch(slice.actions.setUpdateNews({ STATUS: false, error: error }));
    }
  };
}

export function deleteNewsPost(id: String | number, language: string, callback: Function) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      let authToken = await getToken();
      const response = await axios.delete(`${BLOGS_URL}/News/DeleteNewsAsync/${id}`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
          'Content-Type': 'application/json',
          accept: 'text/plain',
          'Accept-Language': language
        }
      });
      dispatch(slice.actions.setDeleteNews({ STATUS: true, ...response?.data?.result }));
      callback(response);
    } catch (error) {
      Sentry.captureException(error);
      dispatch(slice.actions.hasError(error));
      dispatch(slice.actions.setDeleteNews({ STATUS: false, error: error }));
    }
  };
}

export function getNewsSingalPost(id: any, locale: string, callBack: Function) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${BLOGS_URL}/News/GetNewsByIdAsync/${id}`, {
        headers: {
          'Accept-Language': locale
        }
      });
      dispatch(slice.actions.setDetailsNews(response?.data));
      callBack(response);
    } catch (error) {
      Sentry.captureException(error);
      dispatch(slice.actions.hasError(error));
      dispatch(slice.actions.setDetailsNews({ status: 'error', error: error }));
    }
  };
}

export const setNewsLanguagehandler = (language: string) => {
  return async () => {
    dispatch(slice.actions.setNewsLanguage(language));
  };
};

export default slice.reducer;
